import * as React from "react"

import { useTitle } from "../components/browser"

const NotFoundPage = () => {
  useTitle('Access denied')
  return (
    <div className="min-h-screen w-full flex items-center justify-center flex-col">
      <h1 className="font-bold text-xl">Access denied</h1>
      <p className="text-sm text-gray-500">You either do not have propper permissions to access the content or it does not exist.</p>
    </div>
  )
}

export default NotFoundPage
